//sizes/
$size-xlarge: 1400px;
$size-large: 1200px;
$size-medium: 992px;
$size-small: 768px;
$size-minimal: 576px;

$color-gray:#2F2F2F;
$color-green:#55AB26;

