
@mixin shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}
@mixin shadowtr($shadow...) {
  -webkit-filter: drop-shadow($shadow);
  filter: drop-shadow($shadow);
}

@mixin desktop-large {
  @media (min-width: #{$size-large}) {
    @content;
  }
}
@mixin desktop-medium {
  @media (min-width: #{$size-medium}) {
    @content;
  }
}
@mixin desktop-small {
  @media (min-width: #{$size-small}) {
    @content;
  }
}
@mixin desktop-minimal {
  @media (min-width: #{$size-minimal}) {
    @content;
  }
}

.box-shadow {
  @include shadow(0px 4px 7px #00000033);
}

.flex-1 {
  flex:1;
}