body, html {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}
.header {
  background: $color-gray;
  height: 160px;
  color: white;
  display: flex;
  align-items: center;
  @include shadow(0 0 10px #00000070);
  .logo {
    flex:1;
  }
  .phone {
    position: absolute;
    background: $color-green;
    color:white;
    padding: 8px 20px;
    font-size: 20px;
    font-weight: 600;
    right: 10px;
    bottom: -50px;
    display: flex;
    img {
      margin-right: 15px;
      width: 23px;
    }
    @include desktop-small {
      bottom: -50px;
      font-size: 25px;
      img {
        margin-right: 20px;
        width: 30px;
      }
    }
    @include shadow(0 0 10px #00000070);
  }
  ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: flex-end;
    li {
      a {
        border-left: 1px solid white;
        margin-left: 15px;
        padding-left: 15px;
        font-size: 18px;
        color:white;
        text-decoration: none;
      }
      &:first-child {
        a {
          padding-left: 0px;
          margin-left: 0px;
          border-left: none;
        }
      }
    }

  }
  h1 {
    text-align: left;
    font-size: 18px;
    margin-bottom: 8px;
    @include desktop-small {
      font-size: 22px;
    }
  }
  p {
    margin-top: 0px;
    padding-top: 5px;
    border-top: 2px solid $color-green;
    padding-right: 30px;
    display: inline-flex;
    font-size: 15px;
    @include desktop-small {
      font-size: 20px;
    }
  }
}
header {
  display: flex;
  position: relative;
  flex-direction: column;
  @include desktop-small {
    flex-direction: row;
  }
}

.cnt-2 {
  gap:20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  img {
    width: 100%;
  }
  @include desktop-small {
    gap:40px;
    flex-direction: row;
    img {
      width: 50%;
    }
  }
  @include desktop-large {
    gap:60px;
    img {
      width: 100%;
    }
  }
}

.bg-home {
  background: url('../img/bg.png') no-repeat;
  background-size: 60%;
  background-position-y: 170px;
}

.bg-green {
  background-color: $color-green;
  color:white;
}
.icon {
  margin-top: 60px;
  align-items: center;
  display: flex;
  flex-direction: column;
  h2 {
    margin-top: 20px;
    margin-bottom: 5px;
  }
}
h1 {
  font-size: 38px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 25px;
}
h2 {
  font-size: 25px;
  font-weight: 600;
  margin-top: 60px;
  margin-bottom: 15px;
}
p {
  font-size: 17px;
  margin-bottom: 20px;
  @include desktop-small {
    font-size: 20px;
    margin-bottom: 25px;
  }
}
.bg-contact {
  background: url('../img/bg.png') no-repeat;
  background-size: 60%;
  background-position-y: bottom;
  .container {

    @include desktop-medium {
      padding: 0 100px;
    }
    @include desktop-large {
      padding: 0 160px;
    }
  }
  h3 {
    text-align: center;
    color:#707070;
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}
.adress {
  p {
    margin-bottom: 5px;
  }
}